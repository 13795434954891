<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="4">
        <v-form v-model="isValid" @submit.prevent="authorization">
          <v-card class="auth-card pl-6 pr-6 pt-11 pb-11">
            <div class="wrapper__img">
              <img src="../../assets/img/logo.svg" alt="" />
            </div>
            <v-card-title class="auth-title">
              {{ $t("form.title") }}
            </v-card-title>
            <v-card-text>
              <v-text-field
                :label="$t('form.name')"
                v-model="name"
                required
                :rules="emailRules"
              ></v-text-field>
              <v-text-field
                :label="$t('form.password')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :rules="[v => !!v || 'Password is required']"
                @click:append="showPassword = !showPassword"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="pt-0"
              ><v-btn color="primary" block type="submit" :disabled="!isValid">
                {{ $t("form.enter") }}
              </v-btn>
            </v-card-actions>
            <v-alert
              dense
              type="error"
              :value="loginError"
              transition="scale-transition"
            >
              {{ $t("form.error") }}
            </v-alert>
            <div class="description">
              <p>{{ $t("confid.desc") }}</p>
              <span
                >{{ $t("confid.desc2") }}
                <a href="https://www.apiweight.com/redoc/">{{
                  $t("confid.desc3")
                }}</a></span
              >
            </div>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    name: "",
    password: "",
    showPassword: false,
    isValid: true,
    loginError: false,
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid"
    ]
  }),
  methods: {
    ...mapActions(["login"]),
    authorization: function() {
      this.login({ email: this.name, password: this.password }).finally(() => {
        if (this.authStatus) this.$router.push("/");
      });
    }
  },
  computed: {
    ...mapGetters(["authStatus"])
  }
};
</script>
<style lang="scss">
.auth-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 26px;
  text-align: center;
  color: #111421;
  display: block;
  margin-bottom: 20px;
}
.auth-card {
  min-width: 400px;
  text-align: center;
}
.description {
  font-weight: 500;
  font-size: 18px;
  line-height: 12px;
  letter-spacing: 0.045em;
  color: #4e5471;
  p {
    margin: 12px;
  }
  span {
    font-size: 16px;
    font-weight: normal;
  }
}
.wrapper__img {
  img {
    width: 80%;
    max-width: 100%;
    height: 100%;
  }
}
</style>
